export class BrokerLoanDetails {
    constructor(){
        this.brokerCompanyName = null;
        this.purchaseCount = null;
        this.refinanceCount = null;
        this.totalAmount = null;
    }
    public brokerCompanyName: string;
    public totalAmount: number;
    public refinanceCount: number;
    public purchaseCount: number;
    public totalCount: number;
}