


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BrokerLoanDetails } from "@/models/broker-loan-details.model";
import { BASE_API_URL } from "@/config";

import Autocomplete from "vuejs-auto-complete";
import { HollowDotsSpinner } from "epic-spinners";
import Multiselect from "vue-multiselect";

@Component({
  components: {
    Autocomplete,
    HollowDotsSpinner,
    Multiselect
  }
})
export default class BrokerLoanDetailsComponent extends Vue {
  public brokerLoan: any = new BrokerLoanDetails();
  public brokerLoanList: any = "";
  public companyNames: any = [];
  public option = null;
  public brokerId = null;
  public defaultTitle = "Add Loan  Count";
  public defaultSubmitName = "Add";
  public listName = [];
  public showError = false;
  public disableAddButton = false;
  public allBrokerLoanList = [];
  public searchList = [];
  public searchName = "";
  public brokerLoanLoader = false;
  public loadMoreScroll = false;
  public initialPagination = { start: 0, end: 15 };
  public pagination: any = JSON.parse(JSON.stringify(this.initialPagination));
  public currentLengthOnScroll = 0;
  public loanLen = 0;
  public searchString = null;
  public skip = 0;


  public async validateAndSubmit() {
    try {
      this.disableAddButton = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.disableAddButton = false;
        return;
      }
      if (this.brokerLoan.brokerCompanyName) {
        if (this.defaultSubmitName == "Add") {
          let body = {
            data: this.brokerLoan,
            brokerId: this.brokerLoan.brokerCompanyName.userId
          };
          let response = await Axios.post(
            BASE_API_URL + "superAdmin/addBrokerLoan",
            body);

          if (response.status == 200) {
            this.$snotify.success("Successfully Added");
            this.callDefaultFuntn();
          }
        } else {
          let editInfo = {
            data: this.brokerLoan
          };
          let response = await Axios.post(
            BASE_API_URL + "superAdmin/updateBrokerLoan",
            editInfo);
          if (response.status == 200) {
            this.$snotify.success("Successfully Added");
            this.callDefaultFuntn();
          }
        }
      } else {
        this.showError = true;
      }
      this.disableAddButton = false;
    } catch (error) {
      this.disableAddButton = false;
    }
  }
  public async callDefaultFuntn() {
    this.$validator.reset();
    //  let loader = this.$loading.show()
    this.$store.state.wemloLoader = true;
    this.brokerLoan = {};
    this.brokerId = null;
    this.showError = false;
    this.defaultTitle = "Add Loan  Count";
    this.defaultSubmitName = "Add";
    let autocomplete: any = this.$refs.autocomplete;
    if (autocomplete) autocomplete.clear();
    // this.pagination = JSON.parse(JSON.stringify(this.initialPagination));
    this.searchName = "";
    await this.getBrokerLoanList(true);
    this.getTotalLoanCount();
    this.listBroker();
    // loader.hide()
    this.$store.state.wemloLoader = false;
  }
  public searchByCompanyName(searchString) {
    if (searchString != "") {
      this.searchString = searchString;
    } else {
      this.searchString = null;
    }
    this.listBroker();
  }
  public async editCount(data) {
    this.$validator.reset();
    this.defaultTitle = "Edit Loan Count";
    this.defaultSubmitName = "Edit";
    this.brokerLoan = Object.assign({}, data);
  }

  public async getBrokerLoanList(mountCall: boolean = false) {
    try {
      this.skip = mountCall ? 0 : this.skip + 12;
      if (mountCall) {
        this.brokerLoanLoader = true;
      }
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getBrokerLoan",
        {
          searchName: this.searchName,
          options: {
            skip: this.skip,
            limit: 12,
          }
        });
      this.pagination = response.data.pagination;

      if (mountCall) {
        this.brokerLoanList = response.data.BrokerLoanDetails;
        this.allBrokerLoanList = response.data.BrokerLoanDetails;
      } else {
        this.brokerLoanList = this.brokerLoanList.concat(response.data.BrokerLoanDetails);
        this.allBrokerLoanList = this.allBrokerLoanList.concat(response.data.BrokerLoanDetails);
      }

      this.currentLengthOnScroll =  this.brokerLoanList.length;
    } catch (error) {
      console.error(error);
    } finally {
      this.brokerLoanLoader = false;
    }
  }

  public async listBroker() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getBrokerCompanyName/",
        {
          userId: this.$userId,
          searchString: this.searchString
        });
      this.companyNames = response.data.broker;
      // console.log(this.companyNames)
      // console.log(this.companyNames.find(p=>p.companyName == "District lending"))
      // console.log(this.companyNames.find(p=>p.))
      // response.data.broker.forEach(element => {
      //   console.log(!this.listName.includes(element.companyName))
      //   if (!this.listName.includes(element.companyName)) {
      // this.companyNames.push(response.data.broker);
      // }
      // });
    } catch (error) {
      console.log(error);
    }
  }

  public async countTotalLoan() {
    this.brokerLoan.totalCount = 0;
    let refinanceCount = this.brokerLoan.refinanceCount;
    let purchaseCount = this.brokerLoan.purchaseCount;
    if (
      refinanceCount == null ||
      refinanceCount == "" ||
      refinanceCount == undefined
    )
      refinanceCount = 0;
    if (
      purchaseCount == null ||
      purchaseCount == "" ||
      purchaseCount == undefined
    )
      purchaseCount = 0;
    this.brokerLoan.totalCount = Number(refinanceCount) + Number(purchaseCount);
  }

  public async searchByName() {
    try {
      if (this.searchName) {
        this.brokerLoanList = this.searchList.filter(user => {
          if (
            user.brokerCompanyName
              .toLowerCase()
              .includes(this.searchName.toLowerCase())
          ) {
            return user;
          }
        });
      } else {
        this.brokerLoanList = this.allBrokerLoanList;
      }
    } catch (error) {
      console.log(error);
    }
  }
  // public async showMoreDataOnScroll() {
  //   let x: any = document.getElementById("onScroll");
  //   if (
  //     x.scrollTop + x.clientHeight >= x.scrollHeight &&
  //     this.currentLengthOnScroll < this.loanLen
  //   ) {
  //     this.loadMoreScroll =true;
  //     await this.getBrokerLoanList(false);
  //     this.loadMoreScroll =false;
  //
  //   }
  // }
  public async showMoreDataOnScroll() {
    if (!this.loadMoreScroll && this.currentLengthOnScroll < this.loanLen) {
      this.loadMoreScroll = true;
      await this.getBrokerLoanList(false);
      this.loadMoreScroll = false;

    }
  }
  public async getTotalLoanCount() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getTotalLoanCount/",
        {
          userId: this.$userId
        });
      this.loanLen = response.data.loanLen;
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    this.getBrokerLoanList(true);
    this.listBroker();
    this.getTotalLoanCount();
  }
}
